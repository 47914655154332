import util from "lib/util"
export default {
  data() {
    return {
      detail: {},
      coupons: [],
      couponIndex: -1,
      payway: this.$store.state.globalData.isWechat ? 'WEIXIN' : '',
      jsApiParameters:null,
      payCode: '',
      alipayForm: "",
      expid: util.query("expid"),
      sign: util.query("sign"),
      channel: navigator.appVersion.indexOf("MQQBrowser/") > -1 ? 'QB' : 'WAP',
      unit: {
        CARD_TYPE_COUPONS:'折',
        CARD_TYPE_DIRECT: '元',
        CARD_TYPE_FULL_CUT: '元'
      },
      cardClass: {
        CARD_TYPE_COUPONS:'discount',
        CARD_TYPE_FREE: 'free',
        CARD_TYPE_FULL_CUT: 'cut'
      }
    }
  },
  computed:  {
    overprice(){
      return this.detail.overprice + '*' + this.detail.overweight + '=' + (this.$options.filters.formatPrice(this.detail.overprice * this.detail.overweight)).toFixed(2)
    },
    valinsrate(){
      return this.detail.valinsrate ? (this.detail.valins + '*' + this.detail.valinsrate + '=' + (this.$options.filters.formatPrice(this.detail.valins * this.detail.valinsrate))) : this.$options.filters.formatPrice(this.detail.valinspay)
    },
    finalPrice(){
      return this.couponIndex > -1 ? this.coupons[this.couponIndex].totalprice : +this.detail.price || ''
    }
  },
  methods: {
    getDetail(callback) {
      this.$http.get('/apicenter/order.do?method=getOrderInfo', {
        data: {
          expid: this.expid,
          sign: this.sign
        }
      }).then(r => {
        this.loading && this.loading.hide()
        if(callback) {
          callback(r)
        } else {
          if(r.detail.paystatus == 'PAYED'){
            let lastpage = util.getLastPage()
            if(lastpage) {
              this.$router.go(-1)
            } else {
              this.navigator.replace({
                name: "normalDetail",
                query: {
                  expid: this.expid,
                  sign: this.sign
                }
              })
            }
          }
          this.detail = r.detail;
          if(r.detail.role === "THIRD") {
            this.payway = "WEIXIN"
          }
          this.getCoupons()
        }
      }).catch(() => {
        this.loading && this.loading.hide()
      })
    },
    getCoupons(){
      var url = this.$store.state.globalData.isWechat ? '/apicenter/card.do?method=queryCoupon4OrderWx' : '/apicenter/card.do?method=queryCoupon4Order';
      this.$http.post(url, {
        data: {
          sign: this.sign,
          com: this.detail.kuaidiCom,
          channel: this.channel,
          totalprice: this.detail.price,
          expid: this.detail.expid
        }
      }).then(result => {
        if(result.baseInfo) {//这里会为订单详情额外返回一些基本信息
          for(let i in result.baseInfo) {
            this.detail[i] = result.baseInfo[i]
          }
        }
        if(result.data && result.data.length) {
          this.coupons = result.data
        }
      }).catch(() => {})
    },
    selectCoupon(index) {
      if(index === this.couponIndex) {
        this.couponIndex = -1
      } else {
        this.couponIndex = index
      }
    },
    doPay(callback){
      this.loading = this.$loading("正在提交支付...")
      this.$http.post("/apicenter/order.do?method=payinfoV3", {
        data: {
          type:'MKTSENTPAY',
          tradetype: this.payway == 'WEIXIN' && typeof(wx) != "undefined" ? "JSAPI" : "MWEB",
          expid: this.detail.expid,
          sign: this.detail.sign,
          price: this.finalPrice || 5,
          payway: this.payway,
          couponid: this.couponIndex > -1 ? this.coupons[this.couponIndex].id : '',
          platform: "MWWW"
        }
      }).then(result => {
        this.loading.hide()
        callback && callback(result)
      }).catch(() => {
        this.loading.hide()
      })
    },
    pay(){
      if(this.payway != 'WEIXIN' && this.payway != 'ZHIFUBAO') {
        return this.$toast("请选择正确的支付方式")
      }
      this.doPay(result => {
        if(this.payway === "WEIXIN"){
          if(typeof(wx) != "undefined" && result.data.wxpayData){
            this.jsApiParameters = result.data.wxpayData
            this.callpay()
          } else{
            try {
              this.checkPay()
              location.href = result.data.wxpayData.mweb_url
            } catch (e) {
              this.$toast('订单错误，请进入详情查看信息')
            }
          }
        } else {
          this.checkPay()
          this.alipayForm = result.data.alipayData.url
          this.$nextTick(function() {
            document.forms.punchout_form.submit()
          })
          // window.location.href = "http://www.kuaidi100.com/pay/order.do?method=expresspay&expid=" + this.detail.expid + "&from=yidong&callbackurl=" + encodeURIComponent(location.href)
        }
      })
    },
    callpay(){
      if (typeof WeixinJSBridge == "undefined"){
        if( document.addEventListener ){
          document.addEventListener('WeixinJSBridgeReady', this.wxPay, false)
        }else if (document.attachEvent){
          document.attachEvent('WeixinJSBridgeReady', this.wxPay)
          document.attachEvent('onWeixinJSBridgeReady', this.wxPay)
        }
      }else{
        this.wxPay()
      }
    },
    wxPay(){
      /* global WeixinJSBridge */
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        this.jsApiParameters,
        (res) => {
          if(res.err_msg == "get_brand_wcpay_request:ok"){
            window.location.href = location.reload()
          } else if(res.err_msg == "get_brand_wcpay_request:fail"){
            if(this.detai.role !== "THIRD") {
              this.detail.role !== "THIRD" && this.cancelPay()
              this.payway = "QR_WEIXIN"
              this.doPay((result) => {//调起二维码支付
                var data = JSON.parse(result.qrcode)
                if(data && data.codeUrl){
                  this.payCode =  "//www.kuaidi100.com/twoCode.do?h=300&w=300&code=" + data.codeUrl
                  this.checkPay()
                } else {
                  this.$toast(res.err_msg)
                }
              });
            }
          } else if(res.err_msg == "get_brand_wcpay_request:cancel"){
            this.detai.role !== "THIRD" && this.cancelPay()
          }
        }
      );
    },
    cancelPay() {
      this.$http.post("/order/open/exclusiveVisit.do", {
        data: {
          type : "MKTSENTPAYFAIL",
          expid: this.detail.expid,
          sign: this.detail.sign,
        },
        handleFail: false
      })
    },
    checkPay(){
      var count = 0
      var t = setInterval(() => {
        count++
        if(count > 100) clearInterval(t)
        this.getDetail((result) => {
          if(result.detail.paystatus && result.detail.paystatus == "PAYED"){
            clearInterval(t)
            location.reload()
          }
        });
      }, 2000)
    }
  },
  filters: {
    formatPrice(price) {
      var result = ('' + price || 0).indexOf('.') == -1 ? (price || 0) + '.0' : price || 0
      return (+result).toFixed(1)
    }
  },
  created() {
    if(this.$store.state.globalData.isWechat) {
      util.loadScript("//res.wx.qq.com/open/js/jweixin-1.0.0.js", function() {})
    }
    this.login(() => {
      this.loading = this.$loading("正在加载...")
      this.getDetail()
    }, () => {
      this.goLogin()
    })
  }
}